import React, { useContext } from 'react';
import { Switch, Route } from 'react-router-dom';

import LoginScreen from './components/LoginScreen';
import ChatScreen from './components/ChatScreen';

import { localContext } from './Context';

export default function App() {
  const { ws, setChats } = useContext(localContext);

  const loadMessage = ({ data }) => {
    const { uid, sender, message, timestamp } = JSON.parse(data);

    if (!uid || !sender || !message || !timestamp) return;

    setChats((prev) => [...prev, { uid, sender, message, timestamp }]);
  };

  ws.addEventListener('message', loadMessage);

  return (
    <div className="w-screen h-screen flex flex-col justify-center items-center">
      <Switch>
        <Route exact path="/">
          <LoginScreen />
        </Route>
        <Route exact path="/chat">
          <ChatScreen />
        </Route>
      </Switch>
    </div>
  );
}

import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import { Context } from './Context';
import { BrowserRouter as Router } from 'react-router-dom';

ReactDOM.render(
  <Context>
    <Router>
      <App />
    </Router>
  </Context>,
  document.getElementById('root')
);

import React, { useState } from "react";

const localContext = React.createContext();

function Context(props) {
  const [loggedInUser, setLoggedInUser] = useState({});
  const [chats, setChats] = useState([]);

  const [room, setRoom] = useState("");

  //   const backendURL = 'https://auth.kinesis.games';
  const backendURL = "https://auth.kinesis.games";
  //   const socketURL = 'wss://ws.kinesis.games/chat';
  const socketURL = "wss://ws.kinesis.games/chat";

  const ws = new WebSocket(socketURL);

  return (
    <localContext.Provider
      value={{
        loggedInUser,
        setLoggedInUser,
        backendURL,
        chats,
        setChats,
        ws,
        room,
        setRoom,
      }}
    >
      {props.children}
    </localContext.Provider>
  );
}

export { localContext, Context };

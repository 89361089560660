// eslint-disable-next-line
import React, { useState, useContext, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';

import axios from 'axios';

import { localContext } from '../Context';

export default function ChatScreen() {
  const { loggedInUser, backendURL, chats, ws, room } = useContext(
    localContext
  );
  const history = useHistory();

  const [message, setMessage] = useState('');
  const [color, setColor] = useState(300);

  //   const chatEnd = useRef(null);

  if (!loggedInUser['username']) history.push('/');

  const sendMessage = (e) => {
    e.preventDefault();

    let d = new Date();
    const timestamp = new Date(
      d.getUTCFullYear(),
      d.getUTCMonth(),
      d.getUTCDate(),
      d.getUTCHours(),
      d.getUTCMinutes(),
      d.getUTCSeconds()
    );

    const data = {
      uid: loggedInUser.uid,
      sender: loggedInUser.username,
      message: message,
      timestamp: timestamp,
      room: room,
    };

    setMessage('');

    ws.send(JSON.stringify({ ...data, type: 'message' }));
    axios.post(`${backendURL}/api/chats/post`, data);
  };

  const convertDate = (date) => {
    const oldDate = new Date(date);
    return new Date(
      Date.UTC(
        oldDate.getFullYear(),
        oldDate.getMonth(),
        oldDate.getDate(),
        oldDate.getHours(),
        oldDate.getMinutes(),
        oldDate.getSeconds()
      )
    ).toString();
  };

  //   useEffect(() => {
  //     const chatsScroll = document.getElementById('chats');
  //     setInterval(() => {
  //       if (
  //         chatsScroll.scrollHeight - chatsScroll.clientHeight <=
  //           chatsScroll.scrollTop + 1 &&
  //         chatEnd.current !== null
  //       ) {
  //         chatEnd.current.scrollIntoView();
  //       }
  //     }, 500);
  //   }, []);

  const createMessage = (msg) => (
    <div
      className={`w-full flex ${
        msg.uid.toLowerCase() === loggedInUser.uid.toLowerCase()
          ? 'justify-end'
          : 'justify-start'
      }`}
      key={msg['_id']}
    >
      <div
        className={`sm:w-2/5 w-3/4 mx-4 rounded-lg p-2 bg-${
          msg.uid.toLowerCase() === loggedInUser.uid.toLowerCase()
            ? 'green'
            : 'blue'
        }-200 my-2`}
      >
        <div className="w-full font-cutive sm:text-lg text-md font-bold text-gray-900 sm:mb-1 mb-2">
          {msg.message}
        </div>
        <div className="w-full font-mono sm:text-md text-xs text-blue-900 text-left">
          {msg.sender} ~ {convertDate(msg.timestamp)}
        </div>
      </div>
    </div>
  );

  return (
    <div className="w-full h-screen flex flex-col justify-between overflow-hidden">
      <div className="flex w-screen sm:px-20 px-8 sm:py-2 py-4 bg-gray-900 sm:justify-start justify-between items-center">
        <div className="font-sourceCode tracking-widest sm:text-3xl text-lg font-extrabold text-white sm:w-5/6">
          Kinesis Chat
        </div>
        <div className="font-cutive tracking-wide sm:text-2xl text-md rounded border-dashed border-2 text-yellow-300 border-yellow-400 font-bold py-2 px-4 flex items-center">
          {room}
        </div>
      </div>
      <div
        id="chats"
        className="overflow-y-scroll overflow-x-hidden sm:h-11/12 h-4/5 my-2"
      >
        {chats.map((chat) => createMessage(chat))}
        {/* <div className="p-2 bg-blue-500" ref={chatEnd}>
          .
        </div> */}
      </div>
      <div className="sm:px-20 px-8 bg-gray-800 text-white sm:py-4 py-4 sm:mb-0 mb-12 overfllow-hidden w-screen">
        <form className="flex sm:justify-center justify-around items-center sm:w-11/12 w-full mx-auto">
          <input
            type="text"
            placeholder="Type something..."
            className="p-2 rounded-lg bg-gray-300 font-roboto tracking-wide text-blue-900 sm:w-4/5 w-full sm:text-lg text-xs placeholder-xs"
            onChange={(e) => setMessage(e.target.value)}
            value={message}
          />
          <button
            type="submit"
            onClick={(e) => sendMessage(e)}
            className={`sm:w-20 sm:h-12 w-20 h-8 mx-8 ${
              !message ? 'opacity-50' : ''
            } rounded-full border-2 border-blue-300 ${
              color === 300 ? '' : 'bg-blue-300'
            }`}
            onMouseEnter={() => setColor(900)}
            onMouseLeave={() => setColor(300)}
            disabled={!message}
          >
            <i
              className={`w-full h-full sm:text-2xl text-md m-auto sm:my-1 text-blue-${color} ri-send-plane-fill`}
            ></i>
          </button>
        </form>
      </div>
    </div>
  );
}

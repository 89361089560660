import React, { useContext, useState } from 'react';
import { localContext } from '../Context';
import axios from 'axios';
import jwt from 'jwt-decode';
import { useHistory } from 'react-router-dom';

export default function LoginScreen() {
  const {
    setLoggedInUser,
    backendURL,
    setChats,
    ws,
    room,
    setRoom,
  } = useContext(localContext);

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const [message, setMessage] = useState('');
  const [messageColor, setMessageColor] = useState('');

  const history = useHistory();

  const submitLogin = (e) => {
    e.preventDefault();

    setRoom((prev) => (prev.length > 0 ? prev : 'general'));

    const data = {
      username: username,
      password: password,
    };

    setMessage('Submitting...');
    setMessageColor('yellow');

    let decodedData;
    axios
      .post(`${backendURL}/api/users/login`, data)
      .then((res) => {
        if (res && res.status === 200) {
          decodedData = jwt(res.data.jwt);
        }
      })
      .catch(() => {
        setMessage('Invalid Credentials Entered. Please try again.');
        setMessageColor('red');
        setTimeout(() => {
          setMessage('');
          setMessageColor('');
          setUsername('');
          setPassword('');
        }, 1000);
      })
      .then(() => {
        if (decodedData) {
          const { uid, name, username } = JSON.parse(decodedData.iss);

          setLoggedInUser({
            uid: uid,
            name: name,
            username: username,
          });

          ws.send(
            JSON.stringify({
              room: room.length > 0 ? room : 'general',
              type: 'join',
            })
          );

          setMessage('Logging in!');
          setMessageColor('green');
          axios
            .post(`${backendURL}/api/chats/get`, { room: room })
            .then((res) => {
              if (res.data.jwt) {
                const decodedChats = jwt(res.data.jwt);
                console.log('messages: ', JSON.parse(decodedChats.iss));
                setChats(JSON.parse(decodedChats.iss));
              }
            })
            .then(() => {
              setTimeout(() => {
                setMessage('');
                setMessageColor('');
                setUsername('');
                setPassword('');
                history.push('/chat');
              }, 500);
            });
        } else {
          setMessage('Invalid Credentials. Please try again.');
          setMessageColor('red');
          setTimeout(() => {
            setMessage('');
            setMessageColor('');
            setUsername('');
            setPassword('');
          }, 1000);
        }
      });
  };

  const inputClasses =
    'sm:my-2 my-1 sm:p-2 py-1 px-1 w-full rounded border-2 bg-custom-100 placeholder-custom-600 placeholder-opacity-50 border-blue-900 font-roboto sm:text-lg text-sm text-gray-800 focus:bg-blue-200';

  const formData = (
    <form className="flex flex-col mx-auto w-4/5" onSubmit={() => null}>
      <input
        className={inputClasses}
        type="text"
        placeholder="Enter Username..."
        onChange={(e) => setUsername(e.target.value)}
        value={username}
      />
      <input
        className={inputClasses}
        type="password"
        placeholder="Enter Password..."
        onChange={(e) => setPassword(e.target.value)}
        value={password}
      />
      <input
        className={inputClasses}
        type="text"
        placeholder="Enter a Room name..."
        onChange={(e) => setRoom(e.target.value)}
        value={room}
      />
      <button
        className="sm:px-4 px-2 sm:py-2 py-1 bg-gray-700 rounded-lg hover:bg-gray-900 focus:bg-gray-900 sm:text-lg text-md font-roboto tracking-wide text-custom-100 sm:w-1/3 w-3/5 mx-auto sm:my-2 mt-2"
        onClick={(e) => submitLogin(e)}
      >
        Login
      </button>
    </form>
  );

  const messageData = (
    <div
      className={`flex flex-col mx-auto w-4/5 sm:p-2 p-1 sm:my-2 -mt-2 font-roboto sm:text-2xl text-lg text-blue-900 bg-${messageColor}-200 rounded-lg focus:bg-green-300 text-center`}
    >
      {message}
    </div>
  );

  return (
    <div className="m-auto sm:w-1/2 w-4/5 bg-blue-500 flex flex-col items-center justify-center rounded-lg sm:p-4 px-2 py-8">
      <p className="font-sourceCode tracking-wider sm:text-4xl text-lg font-bold text-gray-800 sm:mb-2 mb-4">
        Welcome to Kinesis Chat!
      </p>
      {message ? messageData : formData}
      <div className="sm:text-lg text-xs sm:my-2 mt-2 w-full text-center font-cutive font-bold tracking-tighter text-gray-800">
        Don't have an account yet?
        <br />
        Create one on{' '}
        <a
          className="text-blue-900 px-1 underline font-bold"
          href="https://pass.kinesis.games"
        >
          Kinesis Pass
        </a>
        !
      </div>
    </div>
  );
}
